import { HttpHeaders, HttpRequest, type HttpInterceptorFn } from '@angular/common/http';
import { envs } from '@shared/envs/env';
import { constantVariables } from '@shared/general/configs';

export const headerInterceptor: HttpInterceptorFn = (req, next) => {
  let headers = req.headers;
  headers = handleXProductHeader(headers);
  headers = handleAccessTokenHeader(headers);
  headers = handleRefreshTokenHeader(headers, req);
  headers = handleS3Header(headers, req);
  return next(req.clone({ headers }));
};

const handleXProductHeader = (headers: HttpHeaders) => {
  return headers.append('x-product', envs.xProduct);
};

const handleAccessTokenHeader = (headers: HttpHeaders) => {
  const atk = localStorage.getItem(constantVariables.accessToken);
  if (atk) {
    return headers.append('at', atk);
  }
  return headers;
};

const handleRefreshTokenHeader = (headers: HttpHeaders, req: HttpRequest<any>) => {
  let operationName: string | undefined = undefined;
  if (req.body) {
    operationName = req.body.operationName;
  }
  if (operationName === 'refreshToken' || operationName === 'logout') {
    const rtk = localStorage.getItem(constantVariables.refreshToken);
    return headers.append('rt', rtk!);
  }
  return headers;
};

const handleS3Header = (headers: HttpHeaders, req: HttpRequest<any>) => {
  if (new URL(req.url).origin === envs.s3Url) {
    return headers.append('x-amz-acl', 'public-read');
  }
  return headers;
};
